import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, ImageBackground, SafeAreaView, TouchableOpacity, Image, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";
import AsyncStorage from "../components/AsyncStorage";

const FAQ = () => {
  const navigation = useNavigation();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    AsyncStorage.getData("lang").then((lang) => {
      if (lang) {
        setLang(lang)
      }
    });
  }, []);

  const TextLink = ({ text, link, splitBy, ...rest }) => {
    let parts = text.split(splitBy);
    return (
      <Text {...rest}>
        <Text>{parts[0]}</Text>
        <Text className="underline cursor-pointer" onPress={() => Linking.openURL(link)}>
          {parts[1]}
        </Text>
        <Text>{parts[2]}</Text>
      </Text>
    );
  };

  return (
    <View className="flex-1 md:bg-[#efefef] w-full">
      <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent">
          <NewMobileHeader />
          <MobileHeader title={i18n.t("faqs.faqs")} />
          <ScrollView className="w-full flex-1">
            <Header />
            <View className="items-center content-start">
              <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
                <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("faqs.faqs")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.general")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_1_1")}</Text>
                {/* <TextLink className="text-sm text-[#494949] mt-1 mb-6" text={i18n.t("faqs.general_1_1")} link={"https://members.imperialtreasure.com/benefits"} splitBy={"<link>"} /> */}

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_2_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_3")}</Text>
                {lang === "en" && <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_3_txt")}</Text>}

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_1")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_1_1")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_2")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_2_1")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_3")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_3_1")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_3_2")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_3_3")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_4")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_4_1")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_4_2")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_10")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_10_1")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_5")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_5_1")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_6")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_6_1")}</Text>
                </View>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_7")}</Text>
                </View>
                {/* <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_7_1")}</Text>
                </View> */}
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_7_3")}</Text>
                </View>
                {/* <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_7_2")}</Text>
                </View> */}

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_3_8")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_8_1")}</Text>
                </View>
                {/* <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_8_2")}</Text>
                </View> */}
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_8_3")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_3_8_4")}</Text>
                </View>

                <Text className="text-sm text-[#494949] mt-1 mb-5 font-semibold">{i18n.t("faqs.general_3_9")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_4_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_5_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_6_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_7_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_8_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_9")}</Text>
                <TextLink className="text-sm text-[#494949] mt-1 mb-6" text={i18n.t("faqs.general_9_1")} link={"mailto:sg.club@imperialtreasure.com"} splitBy={"<link>"} />
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_10")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_10_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_11")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_11_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_12")}</Text>
                <TextLink className="text-sm text-[#494949] mt-1 mb-6" text={i18n.t("faqs.general_12_1")} link={"mailto:sg.club@imperialtreasure.com"} splitBy={"<link>"} />
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_13")}</Text>
                <TextLink className="text-sm text-[#494949] mt-1 mb-6" text={i18n.t("faqs.general_13_1")} link={"mailto:sg.club@imperialtreasure.com"} splitBy={"<link>"} />

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.tier")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.tier_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.tier_1_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.tier_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_2_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_2_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_2_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.tier_2_4")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.tier_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_3_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_3_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 ">{i18n.t("faqs.tier_3_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.tier_3_4")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.membership")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-2 ">{i18n.t("faqs.membership_1_1")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_1_1_1")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_1_1_2")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_1_1_3")}</Text>
                </View>

                <Text className="text-sm text-[#494949] mt-6 font-semibold">{i18n.t("faqs.membership_2")}</Text>

                {/* Membership privileges table */}
                <ScrollView horizontal={true}>
                  <View className="min-w-[800px] overflow-hidden overflow-x-scroll">
                    <View className="mt-6">
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-[49%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_1_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_1_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_1_3")}</Text>
                        <Text className="w-[17%] border border-black px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_1_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_2_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_2_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_2_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_2_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_3_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_3_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_3_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_3_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-full border border-black border-t-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_4_1")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_5_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_5_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_5_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_5_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_6_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_6_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_6_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_6_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_7_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_7_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_7_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_7_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_8_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_8_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_8_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_8_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-full border border-black border-t-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_9_1")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_10_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_10_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_10_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_10_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_11_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_11_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_11_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_11_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_12_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_12_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_12_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_12_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_13_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_13_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_13_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_13_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_14_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_14_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_14_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_14_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_15_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_15_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_15_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_15_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_16_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_16_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_16_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_16_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_17_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_17_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_17_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_17_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_18_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_18_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_18_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_18_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_19_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_19_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_19_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_19_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-full border border-black border-t-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_20_1")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_21_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_21_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_21_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_21_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-full border border-black border-t-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.membership_2_22_1")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_23_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_23_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_23_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_23_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_24_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_24_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_24_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_24_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_25_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_25_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_25_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_2_25_4")}</Text>
                      </View>
                    </View>
                  </View>
                </ScrollView>

                <Text className="text-sm text-[#494949] mt-6 mb-1 font-semibold">{i18n.t("faqs.membership_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_3_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_4")}</Text>

                <ScrollView horizontal={true}>
                  <View className="mt-2 min-w-[800px] overflow-hidden overflow-x-scroll">
                    <View className="flex flex-row bg-white">
                      <Text className="w-[24%] border border-black border-r-0 px-2 py-3 text-sm font-semibold">{i18n.t("faqs.membership_4_1_1")}</Text>
                      <Text className="w-[14%] border border-black border-r-0 px-2 py-3 text-sm">{i18n.t("faqs.membership_4_1_2")}</Text>
                      <Text className="w-[16%] border border-black border-r-0 px-2 py-3 text-sm">{i18n.t("faqs.membership_4_1_3")}</Text>
                      <Text className="w-[20%] border border-black border-r-0 px-2 py-3 text-sm">{i18n.t("faqs.membership_4_1_4")}</Text>
                      <Text className="w-[26%] border border-black px-4 py-3 text-sm ">{i18n.t("faqs.membership_4_1_5")}</Text>
                    </View>
                    <View className="flex flex-row bg-white">
                      <Text className="w-[24%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_2_1")}</Text>
                      <Text className="w-[14%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_2_2")}</Text>
                      <Text className="w-[16%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_2_3")}</Text>
                      <Text className="w-[20%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_2_4")}</Text>
                      <Text className="w-[26%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_2_5")}</Text>
                    </View>
                    <View className="flex flex-row bg-white">
                      <Text className="w-[24%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_3_1")}</Text>
                      <Text className="w-[14%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_3_2")}</Text>
                      <Text className="w-[16%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_3_3")}</Text>
                      <Text className="w-[20%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_3_4")}</Text>
                      <Text className="w-[26%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_3_5")}</Text>
                    </View>
                    <View className="flex flex-row bg-white">
                      <Text className="w-[24%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_4_1")}</Text>
                      <Text className="w-[14%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_4_2")}</Text>
                      <Text className="w-[16%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_4_3")}</Text>
                      <Text className="w-[20%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_4_4")}</Text>
                      <Text className="w-[26%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_4_5")}</Text>
                    </View>
                    <View className="flex flex-row bg-white">
                      <Text className="w-[24%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_5_1")}</Text>
                      <Text className="w-[14%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_5_2")}</Text>
                      <Text className="w-[16%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_5_3")}</Text>
                      <Text className="w-[20%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_5_4")}</Text>
                      <Text className="w-[26%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.membership_4_5_5")}</Text>
                    </View>
                  </View>
                </ScrollView>

                {lang === "en" && (
                  <>
                    <TextLink className="text-sm text-[#494949] mt-2 mb-5 font-semibold" text={i18n.t("faqs.membership_5")} link={"https://www.imperialtreasure.com/resources/ck/files/ITC/Terms%20and%20Conditions/ITC%20CRM%20T%26C%20EN%2001042025.pdf"} splitBy={"<link>"} />
                  </>
                )}
                {lang === "zh" && (
                  <>
                    <TextLink className="text-sm text-[#494949] mt-2 mb-5 font-semibold" text={i18n.t("faqs.membership_5")} link={"https://www.imperialtreasure.com/resources/ck/files/ITC/Terms%20and%20Conditions/ITC%20CRM%20T%26C%20CN%2001042025.pdf"} splitBy={"<link>"} />
                  </>
                )}


                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_6_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_7_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membership_8_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membership_8_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membership_8_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membership_8_4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membership_8_5")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_9")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_9_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_10")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_10_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-6 font-semibold">{i18n.t("faqs.membership_10_2")}</Text>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_1")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_2")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_3")}</Text>
                </View>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_4")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_5")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_6")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_7")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_8")}</Text>
                </View>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_9")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_10")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_11")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_12")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_13")}</Text>
                </View>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_14")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_15")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_16")}</Text>
                </View>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_17")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_18")}</Text>
                </View>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_19")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_20")}</Text>
                </View>
                <View className="mb-2 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_21")}</Text>
                </View>

                <Text className="flex-1 text-sm text-[#303030] font-semibold underline">{i18n.t("faqs.membership_10_2_22")}</Text>
                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.membership_10_2_23")}</Text>
                </View>
                <View className="mb-6 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.membership_10_2_24")}</Text>
                </View>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_11")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_11_1")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.point")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_2")}</Text>
                {lang === "en" && (
                  <>
                    <TextLink className="text-sm text-[#494949] mt-1 mb-6 " text={i18n.t("faqs.point_2_1")} link={"https://www.imperialtreasure.com/happening/one-membership-multiple-cities/"} splitBy={"<link>"} />
                  </>
                )}
                {lang === "zh" && (
                  <>
                    <TextLink className="text-sm text-[#494949] mt-1 mb-6 " text={i18n.t("faqs.point_2_1")} link={"https://www.imperialtreasure.com/LG-cn/happening/one-membership-multiple-cities-cn"} splitBy={"<link>"} />
                  </>
                )}
                

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_3_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_4_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_5_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_6_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_7_1")}</Text>

                {lang === "en" && (
                  <>
                    <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_10")}</Text>
                    <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_10_1")}</Text>
                  </>
                )}

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_8_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.point_9")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.point_9_1")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.renewal")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.renewal_1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.renewal_1_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.renewal_2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.renewal_2_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.renewal_3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.renewal_3_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-2 font-semibold">{i18n.t("faqs.renewal_4")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-2 ">{i18n.t("faqs.renewal_4_1_1")}</Text>

                <View className="mb-2 ml-1 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.renewal_4_1")}</Text>
                </View>
                <View className="mb-2 ml-1 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.renewal_4_2")}</Text>
                </View>
                {i18n.locale == "zh" &&
                  <Text className="text-sm text-[ #494949] mt-1 mb-2 ">{i18n.t("faqs.renewal_4_3_1")}</Text>
                }                
                <View className="mb-2 ml-1 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.renewal_4_3")}</Text>
                </View>
                {i18n.locale == "en" &&
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.renewal_4_4")}</Text>
                }
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.renewal_5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.renewal_5_1")}</Text>
              </View>
            </View>

            <Footer />
            <CookieConsent />
          </ScrollView>
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default FAQ;
