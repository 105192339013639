export default {
  login: {
    login: "LOGIN",
    email: "Email",
    mobile: "Mobile",
    mobile2: "MOBILE NO",
    password: "PASSWORD",
    errMsg1: "Please enter email & password",
    errMsg2: "Please enter mobile & password",
    errMsg3: "Your account isn't this region",
    errMsg4: "Incorrect login or password",
    errMsg5: "Incorrect login mobile or password",
    errMsg6: "Your registration is not complete until payment is received.",
    forgotpw: "Forgot password?",
    register: "Register",
    txt: "Not an Imperial Treasure Club member yet? Check out our ",
    txt_2: "Membership Privileges.",
  },
  header: {
    home: "HOME",
    eStore: "E-STORE",
    PRivileges: "PRivileges",
    register: "register",
    highlights: "highlights",
    faq: "faqs",
    mobileApp: "Mobile App",
    tnp: "terms & privacy",
    friendReferral: "Friend Referral",
    profile: "Profile",
  },
  footer: {
    aboutUs: "About Us",
    eStore: "E-STORE",
    faq: "FAQs",
    tnp: "Terms & Privacy",
    pr: "Participating Restaurants",
    profile: "Profile",
    contactUs: "Contact Us",
    copyright: "Copyright © 2025 Imperial Treasure Restaurant Group Pte Ltd.",
  },
  signUp: {
    signUp: "Membership Registration",
    title: "Register as a member of Imperial Treasure Club",
    mustFill: '"*" denotes required fields',
    step1: "Account Details",
    step2: "Security Information",
    step3: "Subscriptions",
    step4: "Reference",
    salutation: "Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    birth: "Birth Date",
    birth_errMsg: "Please select date of birth",
    birth_errMsg2: "You must 18 years old or older",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    emailAddress_errMsg2: "Email already registered",
    confirmEmailAddress: "Confirm Email Address",
    confirmEmailAddress_errMsg: "Please confirm email",
    gender: "Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "Please select language preference",
    password_format: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    password: "*Password",
    password_errMsg: "Please enter password",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please Enter password again",
    step3_consent_opt: "I acknowledge that I have read and agree to the <link>Terms and Conditions<link> set forth herein.",
    tnc_errMsg: "Please agree to Terms & Conditions to proceed",
    step3_title: "By checking the box(es) below, I give my consent to Imperial Treasure Restaurant Group Pte Ltd (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) in accordance with the <link>Privacy Notice<link> and for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of Singapore.",
    step3_q: "I would like to receive marketing and promotional messages and materials from ITRG:",
    step3_q_opt1: "By Email",
    step3_q_opt2: "By Mobile",
    step4_q_err: "Please select where did you hear about our programme",
    step4_q: "Where did you hear about our programme?",
    step4_q_opt1: "Select a reference",
    step4_q_opt2: "Great World, Bar + Grill",
    step4_q_opt3: "Marina Bay Sands, Fine Chinese Cuisine",
    step4_q_opt4: "Paragon, Super Peking Duck",
    step4_q_opt5: "Asia Square, Super Peking Duck",
    step4_q_opt6: "ION Orchard, Fine Teochew Cuisine​",
    step4_q_opt7: "Marina Bay Sands, Fine Teochew Cuisine​",
    step4_q_opt8: "Takashimaya S.C, Fine Shanghai Cuisine​",
    step4_q_opt9: "Great World, Cantonese Cuisine​",
    step4_q_opt10: "Great World, Steamboat",
    step4_q_opt11: "ION Orchard, Steamboat",
    step4_q_opt12: "111 Somerset, Steamboat",
    step4_q_opt13: "Paragon, Yi Dian Xin",
    step4_q_opt14: "Parkway Parade, Yi Dian Xin",
    step4_q_opt15: "Raffles City, Yi Dian Xin",
    step4_q_opt16: "Word of Mouth",
    step4_q_opt17: "Social Media",
    step4_q_opt18: "Others",
    step4_q_opt19: "Jewel Changi Airport, Super Peking Duck",
    step4_q_opt20: "Jewel Changi Airport, Yi Dian Xin",
    step4_q_opt21: "ION Orchard, Huaiyang Cuisine",
    promo_code: "Promo Code",
    promo_code_input_msg: "Please enter a valid promo code (if any)",
    promo_code_err_msg: "Please enter valid promo code.",
    promo_code_re_msg: "Promo code is valid!",
    total: "Total:",
    refresh: "Refresh",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    submitForm: "Proceed to payment",
  },
  aboutUs: {
    aboutUs: "ABOUT US",
    txt1: "The Imperial Treasure Restaurant Group",
    txt2: "With our vast array of iconic Chinese delicacies and our commitment to impeccable service, Imperial Treasure has become synonymous with authentic fine Chinese cuisine. Since the establishment of our first restaurant in 2004, Imperial Treasure Restaurant Group has won the hearts of gourmands and casual diners alike.",
    txt3: "Today, the Group has more than 20 restaurants under its name, as well as over 50 accolades – including: 2-Michelin Stars in the Michelin Guide Shanghai (2017 – 2023) for Imperial Treasure Fine Chinese Cuisine (Yi Feng Galleria), 2-Michelin stars in the Michelin Guide Guangzhou  (2020 – 2022) and 1-Michelin star in the Michelin Guide Guangzhou  (2019) for Imperial Treasure Fine Chinese Cuisine (IGC), 1-Michelin star in the Michelin Guide Singapore (2017 – 2019,  2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (ION Orchard), 1-Michelin star in the Michelin Guide Hong Kong Macau (2018 – 2022) for Imperial Treasure Fine Chinese Cuisine (Tsim Sha Tsui), 1-Michelin star in the Michelin Guide Guangzhou (2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (Taikoo Hui), Michelin Plate by Michelin Guide Guangzhou (2018, 2020), Michelin Plate by Michelin Guide France (2020), Michelin Plate by Michelin Guide Singapore (2018 – 2019), Michelin Plate by Michelin Guide Great Britain & Ireland (2019), Two-Diamond in Meituan-Dianping’s Black Pearl Guide (2018), Top Chinese Restaurants of the World by Ch’ng Poh Tiong, Asia's 50 Best Restaurants (2013 – 2015).",
    txt4: "Imperial Treasure has restaurants in Singapore, Shanghai, Guangzhou, Hong Kong, Incheon, London and Paris.",
  },
  faqs: {
    faqs: "FAQs",
    general: "General",
    general_1: "What is the Imperial Treasure Club?",
    general_1_1: "Imperial Treasure Club is a cardless loyalty programme that allows members to enjoy exclusive privileges and rewards whenever they dine at any participating Imperial Treasure restaurants, both locally and internationally.",
    general_2: "How do I become a member of Imperial Treasure Club?",
    general_2_1: "You may register online at members.imperialtreasure.com. Alternatively, you can sign up at any participating Imperial Treasure restaurants.",
    general_3: "Which are the participating restaurants in Singapore?",
    general_3_txt: "Membership privileges are only valid in Singapore at participating Imperial Treasure restaurants. ",

    general_3_1: "Imperial Treasure Bar + Grill",
    general_3_1_1: "Great World, #02-111 | Tel: (+65) 6732 2232",
    general_3_2: "Imperial Treasure Fine Chinese Cuisine",
    general_3_2_1: "Marina Bay Sands, The Shoppes, #02-04 | Tel: (+65) 6688 7788",
    general_3_3: "Imperial Treasure Super Peking Duck ",
    general_3_3_1: "Paragon, #05-42 | Tel: (+65) 6732 7838",
    general_3_3_2: "Asia Square Tower 1, #02-08 | Tel: (+65) 6636 1868",
    general_3_3_3: "Jewel Changi Airport, #01-219 | Tel: (+65) 6908 6689",
    general_3_4: "Imperial Treasure Fine Teochew Cuisine",
    general_3_4_1: "ION Orchard, #03-05 | Tel: (+65) 6736 2118",
    general_3_4_2: "Marina Bay Sands, The Shoppes, #01-26  | Tel: (+65) 6688 7333",
    general_3_5: "Imperial Treasure Fine Shanghai Cuisine",
    general_3_5_1: "Takashimaya S.C, #04-22 | Tel: (+65) 6836 6909",
    general_3_6: "Imperial Treasure Cantonese Cuisine ",
    general_3_6_1: "Great World, #02-111 | Tel: (+65) 6732 2232",
    general_3_7: "Imperial Treasure Steamboat Restaurant",
    general_3_7_1: "ION Orchard, #04-12A | Tel: (+65) 6636 9339",
    general_3_7_2: "Great World, #01-140 | Tel: (+65) 6235 6008",
    general_3_7_3: "111 Somerset, #02-14 | Tel: (+65) 6235 3882",
    general_3_8: "Treasures Yi Dian Xin",
    general_3_8_1: "Paragon, #B1-08 | Tel: (+65) 6262 3662",
    general_3_8_2: "Parkway Parade, #02-14 | Tel: (+65) 6247 9218",
    general_3_8_3: "Raffles City, #B3-17 | Tel: (+65) 6262 1662",
    general_3_8_4: "Jewel Changi Airport, #01-221 | Tel: (+65) 6908 3668",
    general_3_9: "(*Imperial Treasure reserves the right to make changes to the list of participating restaurants without prior notice.)",
    general_3_10: "Imperial Treasure Huaiyang Cuisine",
    general_3_10_1: "ION Orchard, #04-12A | Tel: (+65) 6636 9339",

    general_4: "What are the fees to become a member of Imperial Treasure Club?",
    general_4_1: "Effective from 1 April 2025, the membership registration fee is S$38 nett.",

    general_5: "How do I pay for the membership application?",
    general_5_1: "For sign up at the participating restaurants, payment can be done at the counter. For online sign up, payment can be done using Visa, Mastercard, AMEX and PayNow.",

    general_6: "What is the validity of the membership?",
    general_6_1: "The membership is valid for 1 year from the date of sign up. E.g. If the registration is made on 17 August 2022, the membership will expire on 31 August 2023.",

    general_7: "Where can I check the expiry date of my membership?",
    general_7_1: "You may log in to the members' portal to check the expiry date of your membership.",

    general_8: "What is the minimum age that I can apply as an Imperial Treasure Club member?",
    general_8_1: "Customers need to be above 18 years of age to apply as an Imperial Treasure Club member.",

    general_9: "Where do I update my personal particulars?",
    general_9_1: "To update or view your personal particulars, please log in to your account via the members’ portal at members.imperialtreasure.com and click on “Profile”. For assistance to update your date of birth, kindly write in to <link>sg.club@imperialtreasure.com<link>.",

    general_10: "How do I check my i-dollars, e-vouchers and other rewards?",
    general_10_1: "You may log in to the members’ portal at members.imperialtreasure.com to check your membership privileges and rewards balance.",

    general_11: "What if I forgotten my password?",
    general_11_1: "To reset your password, visit the members’ portal at members.imperialtreasure.com and click on “Forgot Password”. Follow the on-screen instructions to reset your password.",

    general_12: "What should I do if I have questions regarding my membership account?",
    general_12_1: "If you have any questions regarding your membership account, please email us at <link>sg.club@imperialtreasure.com<link>.",

    general_13: "How do I terminate my membership?",
    general_13_1: "Member may choose to terminate their membership at any time before the expiry date by writing in to us at <link>sg.club@imperialtreasure.com<link> Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused e-vouchers will be deemed forfeited.",

    tier: "Membership Tiers",
    tier_1: "What are the membership tiers?",
    tier_1_1: "There are 3 membership tiers - Silver, Gold and Diamond.",
    tier_2: "How to be a Silver / Gold / Diamond member?",
    tier_2_1: "To become a Silver member, you can sign up with S$38 online or at any participating restaurants.",
    tier_2_2: "To become a Gold member, existing members need to accumulate the qualifying spend of S$10,888* and above within the membership year to qualify.",
    tier_2_3: "To become a Diamond member, existing members need to accumulate the qualifying spend of S$28,888* and above within the membership year to qualify.",
    tier_2_4: "*Qualifying spend is calculated before service charge & prevailing government taxes.",
    tier_3: "How to retain my membership status?",
    tier_3_1: "To maintain as a Silver member, simply renew your membership with a S$20 renewal fee.",
    tier_3_2: "To maintain as a Gold member, simply accumulate the qualifying spend of S$10,888* within the membership year to renew your membership status.",
    tier_3_3: "To maintain as a Diamond member, simply accumulate the qualifying spend of S$28,888* within the membership year to renew your membership status.",
    tier_3_4: "*Qualifying spend is calculated before service charge & prevailing government taxes.",

    membership: "Membership Privileges & Rewards",
    membership_1: "What will I get when I sign up for the Imperial Treasure Club membership?",
    membership_1_1: "Effective from 1 April 2025, the membership registration fee is S$38 nett. Upon completing your registration, you will receive:",
    membership_1_1_1: "1 x S$50 Sign Up e-voucher (Valid for 3 months)",
    membership_1_1_2: "1 x S$100 Birthday e-voucher (Minimum spend of S$280, before service charge & prevailing government taxes in a single receipt) [Voucher issuance is on 1st day of birthday month, valid for use only within the member's birthday month. For sign up during birthday month, the voucher will be issued next year for redemption. One (01) Birthday e-voucher per membership cycle]",
    membership_1_1_3: "2 x Steamboat Premium e-vouchers (Japanese Wagyu Beef & Tiger Garoupa, 1 portion each; valid for 12 months, redeemable for dine-in at Imperial Treasure Steamboat Restaurant)",

    membership_2: "What are the membership privileges? ",
    membership_2_1_1: "Type of Privileges",
    membership_2_1_2: "Silver",
    membership_2_1_3: "Gold",
    membership_2_1_4: "Diamond",

    membership_2_2_1: "Earn i$",
    membership_2_2_2: "Yes",
    membership_2_2_3: "Yes",
    membership_2_2_4: "Yes",

    membership_2_3_1: "S$50 e-voucher",
    membership_2_3_2: "Yes",
    membership_2_3_3: "Yes",
    membership_2_3_4: "Yes",

    membership_2_4_1: "Sign Up Privileges",

    membership_2_5_1: "S$50 Sign Up",
    membership_2_5_2: "Yes",
    membership_2_5_3: "-",
    membership_2_5_4: "-",
    membership_2_6_1: "S$100 Birthday",
    membership_2_6_2: "Yes",
    membership_2_6_3: "Yes",
    membership_2_6_4: "Yes",
    membership_2_7_1: "Wagyu Beef (1 Portion)",
    membership_2_7_2: "Yes",
    membership_2_7_3: "-",
    membership_2_7_4: "-",
    membership_2_8_1: "Tiger Garoupa (1 Portion)",
    membership_2_8_2: "Yes",
    membership_2_8_3: "-",
    membership_2_8_4: "-",

    membership_2_9_1: "Complimentary Treats",

    membership_2_10_1: "Peking Duck",
    membership_2_10_2: "-",
    membership_2_10_3: "Yes",
    membership_2_10_4: "Yes",
    membership_2_11_1: "Suckling Pig",
    membership_2_11_2: "-",
    membership_2_11_3: "-",
    membership_2_11_4: "Yes",
    membership_2_12_1: "Red Wine (Bottle)",
    membership_2_12_2: "-",
    membership_2_12_3: "Yes",
    membership_2_12_4: "Yes",
    membership_2_13_1: "Champagne (Bottle)",
    membership_2_13_2: "-",
    membership_2_13_3: "-",
    membership_2_13_4: "Yes",
    membership_2_14_1: "Complimentary Corkage",
    membership_2_14_2: "-",
    membership_2_14_3: "Yes",
    membership_2_14_4: "Yes",
    membership_2_15_1: "Unlimited Complimentary Selected Steamboat Soup Base",
    membership_2_15_2: "-",
    membership_2_15_3: "Yes",
    membership_2_15_4: "Yes",
    membership_2_16_1: "Unlimited Corkage",
    membership_2_16_2: "-",
    membership_2_16_3: "-",
    membership_2_16_4: "Yes",
    membership_2_17_1: "CNY Festive Cake (Seasonal)",
    membership_2_17_2: "-",
    membership_2_17_3: "1 Cake",
    membership_2_17_4: "2 Cakes",
    membership_2_18_1: "Rice Dumpling Set (Seasonal)",
    membership_2_18_2: "-",
    membership_2_18_3: "1 Set",
    membership_2_18_4: "2 Sets",
    membership_2_19_1: "Mid-Autumn Mooncake Box (Seasonal)",
    membership_2_19_2: "-",
    membership_2_19_3: "1 Box",
    membership_2_19_4: "2 Boxes",

    membership_2_20_1: "Bonus e-voucher",

    membership_2_21_1: "Bonus e-voucher *Accumulated spending required",
    membership_2_21_2: "-",
    membership_2_21_3: "S$500",
    membership_2_21_4: "S$1500",

    membership_2_22_1: "Renewal Privileges",

    membership_2_23_1: "S$28 Renewal	",
    membership_2_23_2: "Yes",
    membership_2_23_3: "-",
    membership_2_23_4: "-",
    membership_2_24_1: "S$100 Birthday",
    membership_2_24_2: "Yes",
    membership_2_24_3: "Yes",
    membership_2_24_4: "Yes",
    membership_2_25_1: "S$30 Steamboat Soup Base",
    membership_2_25_2: "Yes",
    membership_2_25_3: "Yes",
    membership_2_25_4: "Yes",

    membership_3: "How do I know the expiry of my privileges?",
    membership_3_1: 'To check the expiry dates of your privileges, simply log in to the members\' portal and view the details reflected under the "Vouchers" section.',

    membership_4: "Can I combine the e-vouchers for redemption?",

    membership_4_1_1: "e-voucher Type",
    membership_4_1_2: "S$50 Sign Up /\nS$50 Renewal /\nS$28 Renewal",
    membership_4_1_3: "S$100 Birthday",
    membership_4_1_4: "S$50 /\nS$500 Bonus /\nS$1,500 Bonus",
    membership_4_1_5: "Complimentary Treats\n(E.g. Peking Duck,\nSuckling Pig, Champagne)",
    membership_4_2_1: "S$50 Sign Up / S$50 Renewal / S$28 Renewal",
    membership_4_2_2: "No",
    membership_4_2_3: "No",
    membership_4_2_4: "No",
    membership_4_2_5: "Yes",
    membership_4_3_1: "S$100 Birthday",
    membership_4_3_2: "No",
    membership_4_3_3: "No",
    membership_4_3_4: "Yes",
    membership_4_3_5: "Yes",
    membership_4_4_1: "S$50 / S$500 Bonus / S$1,500 Bonus",
    membership_4_4_2: "No",
    membership_4_4_3: "Yes",
    membership_4_4_4: "Yes",
    membership_4_4_5: "Yes",
    membership_4_5_1: "Complimentary Treats (E.g. Peking Duck, Suckling Pig, Champagne)",
    membership_4_5_2: "Yes",
    membership_4_5_3: "Yes",
    membership_4_5_4: "Yes",
    membership_4_5_5: "Yes",

    membership_5: "Click <link>here<link> for terms and conditions of e-vouchers.",

    membership_6: "Why should I register my details at Imperial Treasure Club?",
    membership_6_1: "Registering your details at members.imperialtreasure.com will enable your S$100 Birthday e-voucher to be validated. In addition, you will also receive the latest news on upcoming members’ promotions, rewards, invitations to events and more!",
    membership_7: "How do I identify myself as an Imperial Treasure Club member?",
    membership_7_1: "To identify yourself as a member, simply provide your mobile number to our service staff.",
    membership_8: "How do I enjoy my complimentary treats? ",
    membership_8_1: "Red Wine may be redeemed at all participating restaurants except Treasures Yi Dian Xin.",
    membership_8_2: "Champagne may be redeemed at all participating restaurants except Treasures Yi Dian Xin.",
    membership_8_3: "Peking Duck may be redeemed at Imperial Treasure Fine Chinese Cuisine and Imperial Treasure Super Peking Duck restaurants only, an advance order of one (01) day is required.",
    membership_8_4: "Suckling Pig may be redeemed at Imperial Treasure Fine Chinese Cuisine, Imperial Treasure Super Peking Duck (Paragon), Imperial Treasure Super Peking Duck (Asia Square), Imperial Treasure Fine Teochew Cuisine and Imperial Treasure Cantonese Cuisine restaurants only, an advance order of one (01) day is required.",
    membership_8_5: "Seasonal items are only available for redemption during the respective festive periods. Members who qualify for these items will receive an email notification closer to the festive period for their redemption at participating restaurants. Member must be physically present (proof of identification required) to redeem their privileges.",
    membership_9: "Can I as a corporate customer enjoy corporate discount with the Imperial Treasure Club membership?",
    membership_9_1: "No, corporate discount is not eligible for use together with the Imperial Treasure Club membership. Corporate customers can only choose either corporate discount or the Imperial Treasure Club membership for each receipt.",
    membership_10: "Can I enjoy my Imperial Treasure Club membership privileges in another country where there is an Imperial Treasure restaurant?",
    membership_10_1: "Effective 7 March 2024, members can earn i-dollar (i$) when dining at Imperial Treasure outlets located overseas.",

    membership_10_2: "List of participating overseas restaurants:",

    membership_10_2_1: "Shanghai",
    membership_10_2_2: "Imperial Treasure Fine Chinese Cuisine",
    membership_10_2_3: "L402-403, 4/F, Yi Feng Galleria, No. 99 East Beijing Road, Huangpu District, Shanghai 200002",

    membership_10_2_4: "Guangzhou",
    membership_10_2_5: "Imperial Treasure Fine Chinese Cuisine",
    membership_10_2_6: "L514B, 5/F, International Grand City (IGC), No. 222 Xing Min Road, Tianhe District, Guangzhou, 510623",
    membership_10_2_7: "Imperial Treasure Fine Teochew Cuisine",
    membership_10_2_8: "L307, 3/F, Taikoo Hui, No. 383 Tianhe Road, Tianhe District, Guangzhou, 510620",

    membership_10_2_9: "Hong Kong",
    membership_10_2_10: "Imperial Treasure Fine Chinese Cuisine (One Peking)",
    membership_10_2_11: "10/F, One Peking, 1 Peking Road, Tsim Sha Tsui, Kowloon, Hong Kong",
    membership_10_2_12: "Imperial Treasure Fine Chinese Cuisine (Crowne Plaza)",
    membership_10_2_13: "2/F, Crowne Plaza, 8 Leighton Road, Causeway Bay, Hong Kong",

    membership_10_2_14: "Incheon",
    membership_10_2_15: "Imperial Treasure Fine Chinese Cuisine (Paradise City)",
    membership_10_2_16: "Paradise City, Main Building, 1F, 186 Yeongjonghaeannnam-ro, 321 beon-gil, Jung-gu Incheon City, Korea, Republic of Korea, 22382",
    membership_10_2_17: "Imperial Treasure Dim Sum & Steamboat Restaurant (Paradise City Plaza)",
    membership_10_2_18: "Paradise City Plaza, 1F, 186 Yeongjonghaeannnam-ro, 321 beon-gil, Jung-gu Incheon City, Korea, Republic of Korea, 22382",

    membership_10_2_19: "London",
    membership_10_2_20: "Imperial Treasure Fine Chinese Cuisine",
    membership_10_2_21: "9 Waterloo Place, St. James's, London, SW1Y 4BE",

    membership_10_2_22: "Paris",
    membership_10_2_23: "Imperial Treasure Fine Chinese Cuisine",
    membership_10_2_24: "44 Rue de Bassano, Paris, (inside Hotel La Clef Champs-Élysées Paris)",


    membership_11: "Is my Imperial Treasure Club membership transferrable?",
    membership_11_1: "No. Membership is not transferrable.",

    point: "Earning & Redemption of i-dollar (i$)",

    point_2: "How do I earn i$?",
    point_2_1: "Earn i$1 for every S$10* spent at participating restaurants, both locally and <link>internationally<link> (*excludes service charge, prevailing government taxes, e-vouchers and purchase or use of Imperial Treasure dining vouchers and festive items). Member must be physically present (proof of identification required) at the point of dining. Requests on i$ accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and i$ earned. Combining multiple membership accounts or splitting the bill to earn i$ is strictly not allowed. Balance i$ accrued shall expire upon expiry of membership. Balance i$ accrued is however granted a grace period of three (03) months upon expiry of membership and must be converted to a S$50 e-voucher within the grace period, otherwise be deemed forfeited.",

    point_3: "How do I redeem my i$?",
    point_3_1: "For every i$50 earned, members will automatically receive* a S$50 e-voucher valid for use within the membership year. The S$50 e-voucher can be used to offset the bill when dining at any participating local restaurants. Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Combining multiple membership accounts or splitting the bill to redeem membership privileges is not allowed. (*system automatically converts i$ at a stipulated time daily)",

    point_4: "Can I earn i$ or redeem membership privileges for online purchases made at the e-store?",
    point_4_1: "No. Earning of i$ and/or redeeming of membership privileges are only available for purchases made at participating restaurants, unless otherwise specified.",

    point_5: "Will my i$ expire?",
    point_5_1: "Yes. i$ earned is only valid during the membership period and will expire once the membership expires. Balance i$ will be brought forward for a grace period of three (03) months upon expiry of membership and must be converted to a S$50 e-voucher within the grace period, otherwise deemed forfeited.",

    point_6: "Will my i$ be reflected immediately once I make a purchase?",
    point_6_1: "Yes. The system will reflect i$ earned instantly, but do allow for time lapses that may be caused by any delays in the network during unforeseen circumstances.",

    point_7: "Can I use multiple membership accounts to earn i$ and redeem membership privileges?",
    point_7_1: "No. Combining multiple membership accounts to earn i$ or redeem membership privileges is not allowed.",

    point_8: "Can my family and friends earn i$ or redeem membership privileges if I am not present?",
    point_8_1: "No. Members must be present to enjoy the membership privileges.",

    point_9: "Can I earn i$ or redeem membership privileges for orders made via GrabFood, Deliveroo, FoodPanda or order.imperialtreasure.com? ",
    point_9_1: "No. Earning of i$ and redemption of membership privileges are not eligible for orders made via third party platforms such as GrabFood, Deliveroo, FoodPanda or order.imperialtreasure.com.",

    point_10: "Can I split my bill to earn i$ and/or redeem membership privileges? ",
    point_10_1: "No. Splitting of bill to earn i$ and/or redeem membership privileges is not allowed.",

    renewal: "Renewal",
    renewal_1: "How do I renew my membership?",
    renewal_1_1: "Renewal of membership can be done starting from the 1st day of the expiry month through the members' portal at members.imperialtreasure.com. Alternatively, you can renew your membership at any participating restaurant if your membership has expired. ",

    renewal_2: "How much is the renewal fee?",
    renewal_2_1: "You can renew your membership for S$20 nett.",

    renewal_3: "How do I renew my Gold/Diamond membership?",
    renewal_3_1: "Accumulate a total spend of at least S$10,888* and above (Gold) or S$28,888* and above (Diamond) within the current membership year to qualify for an automatic renewal upon the expiry of the current membership cycle. *Qualifying spend is calculated before service charge & prevailing government taxes.",

    renewal_4: "What will I get when I renew my Imperial Treasure Club membership?",
    renewal_4_1_1: "Effective from 1 April 2025, you will receive:",
    renewal_4_1: "1 x S$28 Renewal e-voucher (Valid for 3 months from date of issue)",
    renewal_4_2: "1 x S$100 Birthday e-voucher (Minimum spend of S$280, before service charge & prevailing government taxes in a single receipt) [Voucher issuance is on 1st day of birthday month, valid for use only within the member's birthday month. For renewal during birthday month, the voucher will be issued next year for redemption. One (01) Birthday e-voucher pre membership cycle]",
    renewal_4_3: "3 x S$30 Steamboat Soup Base e-vouchers (Valid for 12 months, redeemable only for dine-in at Imperial Treasure Steamboat Restaurant) ",
    renewal_4_4: "The new membership cycle and corresponding renewal privileges will be reflected and awarded to your account once your current cycle ends or upon successful renewal if your membership has expired.",

    renewal_5: "Can I renew my Imperial Treasure Club membership before the expiry date?",
    renewal_5_1: "Yes, membership renewal can be done starting from the 1st day of the expiry month through the members' portal at members.imperialtreasure.com. The new membership cycle will commence from the original expiry date.\n\nPlease note that the new membership expiry date will be reflected in your account only after the current membership cycle ends, and renewal e-vouchers will be issued at the beginning of the new membership cycle.",
  },
  tnc: {
    title: "Terms And Privacy",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure Restaurant Group Pte Ltd (“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure with certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a Member. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Membership Tiers",
    membership_1_3_1: "1.3.1 Membership registration, i-dollars, and e-vouchers",
    membership_1_3_1_1: "Where you sign-up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_3_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_3_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, GST, e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of the membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to e-vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_3_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“e-vouchers”) at the rate specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up. Each e-voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The e-voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [https://members.imperialtreasure.com/]. Neither i-dollars nor e-vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_2: "1.3.2 Membership Tiers",
    membership_1_3_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they first join the membership programme and is valid for the period specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee as may be prescribed by Imperial Treasure in its sole discretion on the membership website [https://members.imperialtreasure.com/] for the country in which you signed up. ",
    membership_1_3_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_3_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_3_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Please check the membership programme website regularly for updates. ",
    membership_1_3_3: "1.3.3 Membership Tier Rewards",
    membership_1_3_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [https://members.imperialtreasure.com/]. The validity and terms and conditions of such Rewards are as set out in [https://members.imperialtreasure.com/], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_4: "1.3.4 Redemption of Rewards and e-vouchers",
    membership_1_3_4_1: "Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_3_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_4: "1.4 Amending personal data and terminating membership",
    membership_1_4_1: 'You may amend your personal data or marketing preferences at any time via https://members.imperialtreasure.com (the "Member\'s Portal"). You will need to inform us in writing at sg.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused e-vouchers will be deemed forfeited',
    membership_1_5: "1.5 Termination and cancellation",
    membership_1_5_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member.",
    membership_1_5_2: "We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”), are governed by and construed in accordance with the laws of Singapore.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Singapore courts.",
    membership_3: "3. Consent",
    membership_3_1: "I would like to join the Imperial Treasure Membership club and I consent to the collection, holding, use and disclosure of my personal data in accordance with your Privacy Notice. If I provide Imperial Treasure with any personal data relating to a third party, by submitting such information, I represent and warrant that I have obtained the consent of the third party for Imperial Treasure to collect, use and disclose their personal data in accordance with this Privacy Notice.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure Restaurant Group Pte Ltd, Imperial Treasure Cantonese Cuisine Pte Ltd, Imperial Treasure Noodle House Pte Ltd, Imperial Treasure Fine Dining Restaurant Pte Ltd, Multirich F&B Management Pte Ltd, Imperial Treasure Windows of Hong Kong Pte Ltd and Imperial Treasure Steamboat Pte Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protecting the privacy of visitors to this website and our mobile application (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website, you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites"). ',
    privacy_1_2: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “8. What rights do I have”.",
    privacy_1_3: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "5. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way.",
    privacy_2_2: "We may collect, use and disclose your personal data for the following purposes:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes to:",
    privacy_2_2_1_1: "fulfil your order and deliver it to you;",
    privacy_2_2_1_2: "facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_3: "provide you with service support.",
    privacy_2_2_2: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_2_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and;",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners; and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the personal data for the purposes which we explain at that time.",
    privacy_2_2_4: "For purposes which are required by law: to comply with legal and regulatory obligations, carry out internal audits or investigations, prevent fraud or other unlawful activities, or ensure the safety and security of you and us.",
    privacy_2_2_5: "For purposes that are reasonably necessary or ancillary to the abovementioned purposes.",
    privacy_2_2_6: "For any other purposes which we may have notified you prior to, or at the time of, obtaining your consent.",
    privacy_2_3: "The provision of your name, family name, contact number, gender, date of birth, country code and email address are mandatory so that we can contact you and fulfil any of your order(s): if any required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional.",
    privacy_2_4: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_5: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_5_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_5_2: "to improve the contents of the Website;",
    privacy_2_5_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions;",
    privacy_2_5_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_5_5: "to obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_6: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. SENDING OF EMAILS TO MEMBERS",
    privacy_3_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious emails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to sg.marketing@imperialtreasure.com",
    privacy_4: "4. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING",
    privacy_4_1: "Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your personal data for other purposes, such as those set out above. You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where it is an electronic message, or by contacting us using the details set out below.",
    privacy_4_2: 'Where you have provided us with your consent, Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the Member\'s Portal, for providing marketing material that you have agreed to receive, in accordance with the Personal Data Protection Act 2012 and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    privacy_5: "5. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_5_1: "In order to provide services to you and for the purposes listed at “2 Collection and Use of Personal Data” above, we may disclose and/or transfer your personal data out of Singapore, as described in this Privacy Notice:",
    privacy_5_1_1: "We may share your personal data with our affiliated companies for the purposes of fulfilling a contract with you, providing customer services and membership services, and facilitating your registration as a user of the Website:",
    privacy_5_1_2: "We may permit certain trusted third parties to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; send emails; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and affiliates.",
    privacy_5_1_3: "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others.",
    privacy_5_1_4: "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_5_1_5: "We reserve the right to disclose information, including personal data, in order to comply with a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law; and",
    privacy_5_1_6: "We may disclose aggregated and anonymized data to third parties.",
    privacy_5_2: "Your personal data may be transferred out of Singapore from time to time. Such transfers will be carried out in accordance with the Singapore Personal Data Protection Act 2012.",
    privacy_6: "6. HOW WE PROTECT YOUR INFORMATION",
    privacy_6_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_7: "7. LINKS TO THIRD PARTY SITES",
    privacy_7_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_7_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_8: "8. WHAT RIGHTS DO I HAVE",
    privacy_8_1: "You have the right to ask us for a copy of your personal data; to access or correct; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine readable format, and to ask us to share (port) this data to another data controller.",
    privacy_8_2: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person or where they would infringe the rights of a third party (including our rights). Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_8_3: "To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data.",
    privacy_8_4: 'Protection Officer (See "11. Contact us" below).  To access and correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_8_5: "You may also contact our Data Protection Officer to withdraw your consent to our use of your personal data.",
    privacy_8_6: "Please allow thirty (30) days for your request to be processed.",
    privacy_9: "9. RETENTION OF INFORMATION",
    privacy_9_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_9_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_9_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us",
    privacy_9_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_10: "10. PRIVACY NOTICE CHANGES",
    privacy_10_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_11: "12. CONTACT US",
    privacy_11_1: "If you have any questions about this Privacy Notice, please contact our Data Protection Officer at DPO@imperialtreasure.com.",
  },
  outlets: {
    outlets: "Participating Restaurants",
    restaurant_name: "Restaurant Name",
    address: "Address",
    contact: "Contact",
    email: "Email",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine\nMarina Bay Sands",
    outlets_1_2: "Marina Bay Sands, Level 2 Dining, #02-04,",
    outlets_1_3: "8 Bayfront Avenue, Singapore 018955",
    outlets_1_4: "(+65) 6688 7788",
    outlets_1_5: "mbs@imperialtreasure.com",
    outlets_2_1: "Imperial Treasure Super Peking Duck\nParagon",
    outlets_2_2: "Paragon, #05-42/45,",
    outlets_2_3: "290 Orchard Road, Singapore 238859",
    outlets_2_4: "(+65) 6732 7838",
    outlets_2_5: "paragon@imperialtreasure.com",
    outlets_3_1: "Imperial Treasure Super Peking Duck\nAsia Square",
    outlets_3_2: "Asia Square Tower 1, #02-08/10,",
    outlets_3_3: "8 Marina View, Singapore 018960",
    outlets_3_4: "(+65) 6636 1868",
    outlets_3_5: "asq@imperialtreasure.com",
    outlets_4_1: "Imperial Treasure Fine Teochew Cuisine\nION Orchard",
    outlets_4_2: "ION Orchard, #03-05,",
    outlets_4_3: "2 Orchard Turn, Singapore 238801",
    outlets_4_4: "(+65) 6736 2118",
    outlets_4_5: "fineteochew_ion@imperialtreasure.com",
    outlets_5_1: "Imperial Treasure Fine Teochew Cuisine\nMarina Bay Sands",
    outlets_5_2: "Marina Bay Sands, Bay Level, #01-26",
    outlets_5_3: "2 Bayfront Avenue, Singapore 018972",
    outlets_5_4: "(+65) 6688 7333",
    outlets_5_5: "fineteochew_mbs@imperialtreasure.com",
    outlets_6_1: "Imperial Treasure Fine Shanghai Cuisine\nTakashimaya S.C.",
    outlets_6_2: "Takashimaya S.C., #04-22,",
    outlets_6_3: "391 Orchard Road, Singapore 238872",
    outlets_6_4: "(+65) 6836 6909",
    outlets_6_5: "sh_nac4@imperialtreasure.com",
    outlets_7_1: "Imperial Treasure Cantonese Cuisine\nGreat World",
    outlets_7_2: "Great World, #02-111,",
    outlets_7_3: "1 Kim Seng Promenade, Singapore 237994",
    outlets_7_4: "(+65) 6732 2232",
    outlets_7_5: "cc_gwc@imperialtreasure.com",
    outlets_8_1: "Imperial Treasure Bar + Grill\nGreat World",
    outlets_8_2: "Temporarily Closed",
    outlets_8_3: "Great World, #02-111,",
    outlets_8_4: "1 Kim Seng Promenade, Singapore 237994",
    outlets_8_5: "(By Reservation only)",
    outlets_8_6: "(+65) 6733 2292",
    outlets_8_7: "bar_grill_gw@imperialtreasure.com",
    outlets_9_1: "Imperial Treasure Steamboat Restaurant\nION Orchard",
    outlets_9_2: "ION Orchard, #04-12A,",
    outlets_9_3: "2 Orchard Turn, Singapore 238801",
    outlets_9_4: "(+65) 6636 9339",
    outlets_9_5: "ion_steamboat@imperialtreasure.com",
    outlets_10_1: "Imperial Treasure Steamboat Restaurant\nGreat World",
    outlets_10_2: "Great World, #01-140,",
    outlets_10_3: "1 Kim Seng Promenade, Singapore 237994",
    outlets_10_4: "(+65) 6235 6008",
    outlets_10_5: "steamboat_gwc@imperialtreasure.com",
    outlets_11_1: "Imperial Treasure Steamboat Restaurant\n111 Somerset",
    outlets_11_2: "111 Somerset, #02-14/19,",
    outlets_11_3: "111 Somerset Road, Singapore 238164",
    outlets_11_4: "(+65) 6235 3882",
    outlets_11_5: "steamboat_111@imperialtreasure.com",
    outlets_12_1: "Treasures Yi Dian Xin\nParagon",
    outlets_12_2: "Paragon, #B1-08,",
    outlets_12_3: "290 Orchard Road, Singapore 238859",
    outlets_12_4: "(+65) 6262 3662",
    outlets_12_5: "paragon_ydx@imperialtreasure.com",
    outlets_13_1: "Treasures Yi Dian Xin\nParkway Parade",
    outlets_13_2: "Parkway Parade, #02-14/17,",
    outlets_13_3: "80 Marine Parade Road, Singapore 449269",
    outlets_13_4: "(+65) 6247 9218",
    outlets_13_5: "parkway_ydx@imperialtreasure.com",
    outlets_14_1: "Treasures Yi Dian Xin\nRaffles City",
    outlets_14_2: "Raffles City, #B1-37,",
    outlets_14_3: "252 North Bridge Road, Singapore 179103",
    outlets_14_4: "(+65) 6262 1662",
    outlets_14_5: "rafflescity_ydx@imperialtreasure.com",
    outlets_15_1: "Imperial Treasure Super Peking Duck\nJewel Changi Airport",
    outlets_15_2: "Jewel Changi Airport, #01-219,",
    outlets_15_3: "78 Airport Boulevard, Singapore 819666",
    outlets_15_4: "(+65) 6908 6689",
    outlets_15_5: "spd_jewel@imperialtreasure.com",
    outlets_16_1: "Treasures Yi Dian Xin\nJewel Changi Airport",
    outlets_16_2: "Jewel Changi Airport, #01-221,",
    outlets_16_3: "78 Airport Boulevard, Singapore 819666",
    outlets_16_4: "(+65) 6908 3668",
    outlets_16_5: "jewel_ydx@imperialtreasure.com",
    outlets_17_1: "Imperial Treasure Huaiyang Cuisine\nION Orchard",
    outlets_17_2: "ION Orchard, #04-12A,",
    outlets_17_3: "2 Orchard Turn, Singapore 238801",
    outlets_17_4: "(+65) 6636 9339",
    outlets_17_5: "huaiyang_ion@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILEGES",
    privilege_effective: "- Effective 1 April 2025 -",
    subtitle: "Experience Exclusive Dining Privileges with Imperial Treasure Club",
    privilege: "MEMBERSHIP PRIVILEGES",
    privelege_txt_new: "Earn i$1 with every S$10 spent# when you dine at any participating Imperial Treasure restaurants, both locally and <link>internationally<link>. \nAccumulate i$50 to receive a S$50 e-voucher for use at any participating restaurants in Singapore.",
    privelege_txt: "Earn i-Dollar (i$1 with every S$10 spent",
    privelege_txt2: ") on your spend at any participating restaurants. \nAccumulate i$50 to receive S$50 e-voucher for use at any participating restaurants.",
    silver: "SILVER",
    silver_signup: "S$38 SIGN UP",
    silver_1: "1 x S$50 Sign Up e-vouchers (valid for 3 months)",
    silver_2: "1 X S$100 Birthday e-voucher (minimum spend of S$280, before service charge & prevailing government taxes in a single receipt) [Voucher issuance is on 1st day of birthday month, valid for use only within the member's birthday month. For sign up during birthday month, the voucher will be issued next year for redemption. One (01) Birthday e-voucher per membership cycle]",
    silver_3: "2 x Steamboat Premium e-vouchers (Japanese Wagyu Beef & Tiger Garoupa, 1 portion each; valid for 12 months, redeemable for dine-in at Imperial Treasure Steamboat Restaurant)",
    silver_renewal: "S$20 RENEWAL (From 2nd Year Onwards)",
    silver_4: "1 x S$28 Renewal e-voucher (valid for 3 months)",
    silver_5: "1 X S$100 Birthday e-voucher (minimum spend of S$280, before service charge & prevailing government taxes in a single receipt) [Voucher issuance is on 1st day of birthday month, valid for use only within the member's birthday month. For renewal during birthday month, the voucher will be issued next year for redemption. One (01) Birthday e-voucher per membership cycle]",
    silver_6: "3 x S$30 Steamboat Soup Base e-vouchers (valid for 12 months, redeemable for dine-in at Imperial Treasure Steamboat Restaurant)",
    gold: "GOLD",
    gold_1: "Upgrade to GOLD tier when you spend S$10,888# and above within the membership year.",
    gold_2: "Receive the following:",
    gold_3: "Waiver of Annual Renewal Fee",
    gold_4: "1 x S$500 Bonus e-voucher",
    gold_5: "9 X Complimentary Corkage e-vouchers",
    gold_6: "Unlimited Complimentary Selected Steamboat Soup Base e-voucher",
    gold_7: "Complimentary Treats: \n1 x Peking Duck \n1 x Bottle of Red Wine \n1 x CNY Festive Cake (seasonal) \n1 x Rice Dumpling Set (seasonal) \n1 x Box of Mooncake (seasonal)",
    diamond: "DIAMOND",
    diamond_1: "Upgrade to DIAMOND tier when you spend S$28,888# and above within the membership year.",
    diamond_2: "All of GOLD* tier benefits plus:",
    diamond_3: "1 x S$1,500 Bonus e-voucher",
    diamond_4: "Unlimited Corkage e-voucher",
    diamond_5: "Complimentary Treats: \n1 x Suckling Pig \n1 x Bottle of Champagne \n1 x CNY Festive Cake (seasonal)\n1 x Rice Dumpling Set (seasonal) \n1 x Box of Mooncake (seasonal)",
    remarks: "# Excludes service charge, prevailing government taxes, e-vouchers redemption and purchase or use of Imperial Treasure dining vouchers and festive items.\n*Each member is eligible for one (01) set of Gold tier benefits per membership cycle.",
    sign_up_now: "Sign up now",
  },
  resetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    new_password: "*New password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm new password",
    confirm_password_errMsg: "Please confirm password",
    reset_password2: "Reset password",
    has_been_reset: "Password has been reset!",
    login: "Login",
  },
  forgetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    forgot_password: "Forgot password?",
    reset_pw_method: "Please select password reset method",
    by_mobile: "By mobile",
    by_email: "By email",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    reset_password2: "Reset password",
    reset_password_sent_email: "Reset password link has been sent to your email.",
    reset_password_sent_mobile: "Reset password link has been sent to your mobile.",
    next: "Next",
    back: "Back",
  },
  updateEmail: {
    title: "Update Email",
    member_profile: "Member Profile",
    updateEmail: "Update Email",
    new_email: "*New Email Address",
    sent: "Sent",
    send_token: "Send Token",
    email_errMsg1: "Please enter valid email",
    email_errMsg2: "Email already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  updateMobile: {
    title: "Update Mobile",
    member_profile: "Member Profile",
    updateMobile: "Update Mobile",
    new_mobile: "*New Mobile No.",
    sent: "Sent",
    send_token: "Send Token",
    mobile_errMsg1: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  coupon: {
    title: "Vouchers",
    member_profile: "Member Profile",
    coupon: "Vouchers",
    birthdayNote: "Your birthday e-voucher will only be reflected during your birthday month.",
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    type_of_vouchers_opt1: "Please select type of vouchers",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Expiry Date:",
    redeemed_store: "Redeemed Outlet:",
    vaild_date: "Valid Date",
    description: "Description",
    vouchers_code: "Voucher Code",
    done: "Done",
    no_coupon_msg: "No coupons available",
  },
  editProfile: {
    title: "Edit Profile",
    member_profile: "Member Profile",
    editProfile: "Edit Profile",
    general: "General Information",
    security: "Security",
    subscriptions: "Subscriptions",
    salutation: "*Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    gender: "*Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    first_name: "*Given Name",
    first_name_errMsg: "Please enter given name",
    last_name: "*Family Name",
    last_name_errMsg: "Please enter family name",
    birth: "*Date of Birth",
    mobile: "*Mobile No.",
    mobile_change: "Change",
    email: "*Email",
    email_change: "Change",
    language: "*Language Preference",
    language_opt1: "Please select your language preference",
    language_opt2: "English",
    language_opt3: "简体中文",
    update: "Update",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please confirm password",
    subscriptions_txt1: "By checking the box(es) below, I give my consent to Imperial Treasure Restaurant Group Pte Ltd (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) in accordance with the <link>Privacy Notice<link> and for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of Singapore.",
    subscriptions_txt2: "I would like to receive marketing and promotional messages and materials from ITRG:",
    by_email: "By Email",
    by_mobile: "By Mobile",
    update_success: "Update Success!",
    done: "Done",
    password_update_success: "Password Update Success!",
  },
  profile: {
    title: "Member",
    logout: "Logout",
    click_to_renew: "CLICK TO RENEW​",
    activation_now: "ACTIVATE NOW",
    menber_id: "Member ID:",
    member: "member",
    active: "Active",
    expired: "Expired",
    salutation: "Salutation:",
    mobile: "Mobile No.:",
    birth: "Date of Birth:",
    password: "Password:",
    ellipsis: "········",
    change: "Change",
    gender: "Gender:",
    email: "Email:",
    cycle_start_date: "Tier Start Date:",
    first_joined_date: "First Joined Date:",
    optin_email: "Opt-in Email:",
    optin_sms: "Opt-in SMS:",
    cycle_expiry_date: "Cycle Expiry Date:",
    language_preference: "Language Preference:",
    spend: "S$ Spend",
    expires_on: "  Expires on",
    spend_required: "Nett Spend Required",
    to_upgrade_to: "to upgrade to",
    diamond_membership: "Diamond Membership",
    nett_spend_required: "Nett Spend required to maintain",
    nett: "Nett",
    to_maintain_to: "to maintain to",
    silver_member: "Silver",
    gold_member: " Gold",
    diamond_member: " Diamond",
    total_nett_spend: "Total Nett Spend",
    current_membership: "in current membership year*",
    i_dollar: "i-Dollar",
    last_update: "  Last Update : ",
    i_dollar_earned: "i-Dollar Earned",
    i_dollar_converted: "i-Dollar Converted",
    i_dollar_balance: "i-Dollar Balance",
    i_dollar_automatically: "$50 i-Dollars are automatically converted to $50 e-voucher",
    vouchers: "Vouchers",
    currently_active: "Currently Active",
    cash_vouchers: "Cash Vouchers",
    food_vouchers: "Food/Promotion Vouchers",
    click_to_view: "CLICK TO VIEW",
    transaction: "Transactions",
    this_month: "This Month",
    last_3_months: "Last 3 Months",
    last_12_months: "Last 12 Months",
    transaction_details: "Transaction Details",
    transaction_date: "Transaction Date",
    transaction_time: "Transaction Time",
    outlet: "Outlet",
    receipt: "Receipt No.",
    payment_method: "Payment Method",
    receipt_details: "Receipt Details",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS EARNED:",
    total: "Total :",
    done: "Done",
    thankyou_msg: "Thank you for dining with us!",
    activate_membership: "ACTIVATE MEMBERSHIP",
    activate_membership_1: "Activate your membership with just S$20 to enjoy the following privileges:",
    activate_membership_2: "Earn i$1 with every $10 spent. Accumulate i$50 to receive a $50 e-voucher",
    activate_membership_3: "1 x S$28 Renewal e-voucher (valid for 3 months)",
    activate_membership_4: "1 X S$100 Birthday e-voucher, minimum spend of S$280 applies, valid only during birthday month",
    activate_membership_5: "3 x S$30 Steamboat Soup Base e-vouchers (valid for 12 months)",
    activate_membership_7: "Be the first to receive latest updates and exclusive invitations to private events! ",
    renew_membership: "RENEW MEMBERSHIP",
    renew_membership_1: "Renew your membership with just S$20 to enjoy the following privileges:",
    renew_membership_2: "Earn i$1 with every $10 spent. Accumulate i$50 to receive a $50 e-voucher",
    renew_membership_3: "1 x S$28 Renewal e-voucher (valid for 3 months)",
    renew_membership_4: "1 x S$100 Birthday e-voucher, minimum spend of S$280 applies, valid only during birthday month",
    renew_membership_5: "3 x S$30 Steamboat Soup Base e-vouchers (valid for 12 months)",
    renew_membership_6: "Be the first to receive latest updates and exclusive invitations to private events! ",
    confirm_to_renew: "Confirm To Renew",
    confirm_to_activate: "Confirm To Activate",
    change_password: "Change Password",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter & confirm password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)​",
    confirm_password: "*Confirm Password",
    close: "X CLOSE",
    member_activated: "Member activated",
    password_updated: "Password updated",
    friend_referral: "Friend Referral",
    view_more: "VIEW MORE",
    add_friend: "ADD",
    silver_tier: "Silver ",
    gold_tier: "Gold ",
    diamond_tier: "Diamond ",
    spend_date_format: "Expired year",
  },
  friendReferral: {
    title: "Friend Referral",
    note1: "Refer a friend and you will get S$10 e-voucher for every successful referral.",
    note2: "For every successful referral, the referee will receive a Peking duck e-voucher to purchase our Signature Peking Duck at S$78 (U.P: S$178)",
    email: "EMAIL",
    referee_email: "Referee's Email",
    emailAddress_errMsg: "Please enter valid email.",
    mobile: "MOBILE NUMBER",
    referee_mobile: "Referee’s Mobile",
    mobile_errMsg: "Please enter valid mobile",
    mobile_errMsg2: "Phone already registered",
    name: "NAME",
    referee_name: "Referee's Name",
    name_errMsg: "Please enter referee's name.",
    referral_sucMsg: "You have successfully referred your friend!",
    referral_errMsg: "Referee is an existing registered Imperial Treasure Club member.",
    submit: "Refer Now",
    tnc: "Terms and Conditions"
  },
  transactionDetails: {
    transaction_date: "Transaction Date",
    outlet: "Outlet",
    receipt_no: "Check/Receipt No",
    amount_spend: "Total Paid",
    amount_qualified_for_i_dollar: "Amount Qualified For i-Dollar",
    i_dollars: "i-Dollars",
    selected_start_date: "Selected start date",
    selected_end_date: "Selected end date",
    no_tranMsg: "No transaction records during the selected period.",
    action: "Action",
    apply: "Apply",
    view: "View",
  },
  contactUs: {
    title: "Contact Us",
    title_message: "Please complete and submit your feedback below and we will get back to you within 3-5 working days.",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    type: "Type",
    type_opt1: "Please enter your feedback message type :",
    type_opt2: "Options",
    type_opt3: "Enquiry",
    type_opt4: "Feedback",
    type_opt5: "Newsletter Subscription",
    type_errMsg: "Please enter your feedback message type",
    message: "Message",
    message_errMsg: "Please enter your feedback message",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Thank you for reaching out and providing us with valuable feedback. \nWe will respond to you very soon.",
    submit: "Submit",
  },
  mobileApp: {
    title: "IMPERIAL TREASURE MOBILE APP",
    desc_1: "Earn Rewards as You Dine",
    desc_2: "Download Imperial Treasure Mobile App Now!"
  },
  languageSetting: {
    title: "Language Setting",
    setting: "Language Preference",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "Please select language preference",
    submit: "Submit",
  },
};